import React, { useEffect } from 'react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import config from '../config';

type SentryWrapperProps = {
  children: React.ReactElement;
};

const SentryWrapper: React.FC<SentryWrapperProps> = ({
  children,
}: SentryWrapperProps) => {
  useEffect(() => {
    if (config.SENTRY_ENABLED) {
      Sentry.init({
        dsn: config.SENTRY_DST,
        integrations: [
          new Integrations.BrowserTracing(),
          new CaptureConsole({
            levels: ['error', 'warn'],
          }),
        ],
        environment: config.ENV,
        release: `zeals-dx-enduser-${process.env.VERSION || 'unknown'}`,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.1,
      });
    }
  });

  // do not use sentry if develop to save cost
  if (!config.SENTRY_ENABLED) {
    return children;
  }

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryWrapper;
