import { makeStyles } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  contentContainer: {
    height: '100%',
    gridRow: 2,
    margin: 0,
    maxWidth: '600px',
    padding: theme.spacing(3),
    width: '100%',
  },
  loadingIndicatorContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:disabled': {
      background: rgba(theme.palette.secondary.main, 0.5),
      color: theme.palette.common.white,
    },
    '&:hover': {
      background: '#00A496',
      color: theme.palette.common.white,
    },
  },
  checkbox: {
    color: theme.palette.common.white,
    '& .MuiIconButton-label': {
      position: 'relative',
      zIndex: 0,
    },
    '&:not($checked) .MuiIconButton-label:after': {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: 'absolute',
      backgroundColor: 'white',
      zIndex: -1,
    },
    '&$checked': {
      color: theme.palette.common.white,
    },
  },
  checked: {},
  error: {
    color: theme.palette.common.white,
  },
  inputFieldContainer: {
    width: '100%',
    maxWidth: '500px',
  },
  inputField: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: 'underline',
  },
  select: {
    background: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  selectIcon: {
    color: theme.palette.common.black,
  },
  selectRoot: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  subTitle: {
    color: theme.palette.common.white,
    fontSize: '1em',
  },
  title: {
    color: theme.palette.common.white,
    fontSize: '2em',
    fontWeight: 900,
  },
}));
