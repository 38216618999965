import React from 'react';
import { CompanyLogo, CompanyLogoData } from '@zeals/shared-components';
import useStyles from './styles';

type CompanyHeaderBlockProps = {
  company?: CompanyLogoData.CompanyLogoTypes.Company;
};

const CompanyHeaderBlock: React.FC<CompanyHeaderBlockProps> = ({ company }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className="companyLogo">
        <CompanyLogo company={company} scale={2} />
      </div>
    </div>
  );
};

export default CompanyHeaderBlock;
