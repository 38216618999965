import { action, observable, runInAction } from 'mobx';

import { RoomInstance } from '@zeals/shared-types/build/matching/entities';
import { AdminInstance } from '@zeals/shared-types/build/admin/entities';
import {
  ListRoomsRequest,
  ListRoomsResponse,
} from '@zeals/shared-types/build/matching';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';
import { RootStore } from './rootStore';
import api from '../services/api';

class MatchingStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @observable admin: Partial<AdminInstance> = null;

  @observable room: RoomInstance = null;

  @action getRoom = async (id: string): Promise<void> => {
    if (this.fetching) return;

    this.fetching = true;

    try {
      const response = await api.getRoom(id);
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      if (!response.data.admin || !response.data.room) {
        throw new Error();
      }

      this.admin = response.data.admin;
      this.room = response.data.room;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action listRooms = async (
    request: ListRoomsRequest
  ): Promise<ApiResponseWrapper<ListRoomsResponse>> => {
    return api.listRooms(request);
  };
}

export default MatchingStore;
