import apisauce from 'apisauce';
import transformResponse from '@zeals/shared-utils';
import qs from 'qs';

import {
  GetVideoTokenRequest,
  GetVideoTokenResponse,
} from '@zeals/shared-types/build/api/video';
import {
  GetConversationTokenRequest,
  GetConversationTokenResponse,
} from '@zeals/shared-types/build/api/conversation';

import {
  CreateEnduserRequest,
  CreateEnduserResponse,
  GetCurrentUserResponse,
} from '@zeals/shared-types/build/api/enduser';

import {
  ProcessMatchingResponse,
  GetRoomResponse,
} from '@zeals/shared-types/build/api/matching';

import {
  ListRoomsRequest,
  ListRoomsResponse,
} from '@zeals/shared-types/build/matching';

import { SendFeedbackRequest } from '@zeals/shared-types/build/api/reservation';
import { api as apiTypes } from '@zeals/shared-types';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import config from '../config';
import { rootStore } from '../stores/rootStore';

const api = apisauce.create({
  baseURL: config.SERVER_URL,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addRequestTransform((request) => {
  request.headers.Authorization = rootStore.authStore.accessToken;
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    rootStore.authStore.reset();
  }
});

export default {
  createEnduser: async (
    request: CreateEnduserRequest
  ): Promise<ApiResponseWrapper<CreateEnduserResponse>> => {
    return transformResponse<CreateEnduserResponse>(api.post, {
      payload: request,
      url: '/enduser/auth/register',
    });
  },
  getConversationToken: (
    request: GetConversationTokenRequest
  ): Promise<ApiResponseWrapper<GetConversationTokenResponse>> => {
    return transformResponse<GetVideoTokenResponse>(api.get, {
      url: `/enduser/conversations/${request.conversationId}/token`,
    });
  },
  getCurrentUser: async (): Promise<
    ApiResponseWrapper<GetCurrentUserResponse>
  > => {
    return transformResponse<GetCurrentUserResponse>(api.get, {
      url: `/enduser/endusers/me`,
    });
  },
  getRoom: async (id: string): Promise<ApiResponseWrapper<GetRoomResponse>> => {
    return transformResponse<GetRoomResponse>(api.get, {
      url: `/enduser/matching/rooms/${id}`,
    });
  },
  listRooms: async (
    request: ListRoomsRequest
  ): Promise<ApiResponseWrapper<ListRoomsResponse>> => {
    return transformResponse<ListRoomsResponse>(api.get, {
      url: `/enduser/matching/rooms?${qs.stringify(request)}`,
    });
  },
  getVideoSessionToken: (
    request: GetVideoTokenRequest
  ): Promise<ApiResponseWrapper<GetVideoTokenResponse>> => {
    return transformResponse<GetVideoTokenResponse>(api.get, {
      url: `/enduser/videos/${request.videoSessionId}/token`,
    });
  },
  processMatching: async (): Promise<
    ApiResponseWrapper<ProcessMatchingResponse>
  > => {
    return transformResponse<ProcessMatchingResponse>(api.put, {
      url: `/enduser/matching/me`,
    });
  },
  sendFeedback: async (
    request: SendFeedbackRequest
  ): Promise<ApiResponseWrapper<void>> => {
    return transformResponse<void>(api.put, {
      payload: request,
      url: `/enduser/reservations/-/feedback`,
    });
  },
};
