import { ENVS, EnvironmentConfig } from './types';

const staging: EnvironmentConfig = {
  ENV: ENVS.STAGING,
  SERVER_URL: 'https://api.stg.omotenashi.ai/v1',
  SENTRY_DST:
    'https://0214400137c8405889da21eadbf7842a@o247680.ingest.sentry.io/5544232',
  SENTRY_ENABLED: true,
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyAO2PS9NtvgJH80IXHi46JwEgn_I28c7A8',
    authDomain: 'zeals-dx-stg.firebaseapp.com',
    databaseURL: 'https://zeals-dx-stg.firebaseio.com',
    projectId: 'zeals-dx-stg',
    storageBucket: 'zeals-dx-stg.appspot.com',
    messagingSenderId: '910369079356',
    appId: '1:910369079356:web:a59d030a8bc15872b1c339',
    measurementId: 'G-85H512NFNC',
  },
  TAG_MANAGER_CONTAINER_ID: 'GTM-PN7QG7T',
  TWILIO_CONNECT_OPTIONS: {
    tracks: [],
    maxAudioBitrate: 16000,
    // https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#mobile-browser-grid-recommended-settings
    bandwidthProfile: {
      video: {
        mode: 'presentation',
        maxTracks: 6, // the recommendation is 4 but since we have to switch some tracks between so having some buffer here
        maxSubscriptionBitrate: 2500000,
        dominantSpeakerPriority: 'standard',
        renderDimensions: {
          high: { height: 1080, width: 1920 },
          standard: { height: 640, width: 360 },
        },
      },
    },
    dominantSpeaker: true,
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    networkQuality: { local: 3, remote: 1 },
  },
};

export default staging;
