import React from 'react';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const ChatVideoLoadingBlock: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <Grid container className={classes.container} direction="column">
      <Grid
        className={classes.loadingText}
        container
        item
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="secondary" />
        </Grid>

        <Grid item>
          <Typography variant="h6" color="textSecondary">
            {translate('page.video.pleaseWait')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatVideoLoadingBlock;
