import { makeStyles } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';
import { isIOS } from 'react-device-detect';

export default makeStyles((theme) => {
  return {
    ratingPageContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      height: '100%',
      justifyContent: 'center',
      maxHeight: '100%',
      margin: 0,
      maxWidth: '100%',
      minHeight: isIOS ? '-webkit-fill-available' : '100vh',
      padding: theme.spacing(2),
      width: '100%',
      '& >.pageTitle': {
        padding: theme.spacing(4),
        textAlign: 'center',
      },
      whiteSpace: 'break-spaces',
    },
    commentContainer: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
    },
    commentArea: {
      padding: theme.spacing(3),
      '& div': {
        color: theme.palette.common.white,
      },
      width: '100%',
    },
    fullWidthContainer: {
      width: '100%',
      '& >.buttonArea': {
        padding: theme.spacing(3),
        '& >.button': {
          background: theme.palette.secondary.main,
          color: theme.palette.common.white,
          '&:disabled': {
            background: rgba(theme.palette.secondary.main, 0.5),
            color: theme.palette.common.white,
          },
          '&:hover': {
            background: '#00A496',
            color: theme.palette.common.white,
          },
        },
      },
    },
    icon: {
      padding: theme.spacing(1),
    },
    separator: {
      backgroundColor: theme.palette.common.white,
      height: '2px',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
      width: '80vw',
    },
  };
});
