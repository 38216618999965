import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { Button, FormTextField, Copyright } from '@zeals/shared-components';

import RatingBlock from './rating';
import QuestionBlock from './question/QuestionBlock';

import { useStores } from '../../stores';

import ratings from './ratings';

import useStyles from './styles';
import UnknownErrorPage from '../errors/UnknownError';

type RatingPageParams = {
  roomId: string;
};

type SubmitProps = {
  attitude: number;
  planning: number;
  knowledge: number;
  usability: number;
  quality: number;
  recommendation: number;
  comment: string;
};

const RatingPage: React.FC = () => {
  const methods = useForm({
    mode: 'onChange',
  });

  const { formState, handleSubmit } = methods;

  const classes = useStyles();
  const history = useHistory();
  const { reservationStore } = useStores();
  const { roomId } = useParams<RatingPageParams>();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation();

  const onSubmit = (data: SubmitProps) => {
    setLoading(true);

    const compositedFeedback = ratings.map((rating, index) => {
      return `${translate(rating.title, {
        sequence: index + 1,
      })}：${data[rating.name]}`.replace('\n', '');
    });

    reservationStore
      .sendFeedback({
        roomId,
        feedback: compositedFeedback.join('\n'),
      })
      .then((response) => {
        setLoading(false);
        if (response.error) {
          setShowError(true);
          return;
        }

        setShowError(false);
        history.push(`/rooms/${roomId}/thanks`);
      });
  };

  if (showError) {
    return <UnknownErrorPage />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.ratingPageContainer}>
          <Typography
            className="pageTitle"
            color="textSecondary"
            variant="subtitle1"
          >
            {translate('page.rating.title')}
          </Typography>
          {ratings.map((rating, index) => {
            if (rating.kind === 'number') {
              return (
                <div key={rating.name}>
                  <QuestionBlock
                    title={translate(rating.title, {
                      sequence: index + 1,
                    })}
                  >
                    <RatingBlock
                      name={rating.name}
                      props={{
                        classes: { icon: classes.icon },
                        size: 'large',
                      }}
                    />
                  </QuestionBlock>
                  <div className={classes.separator} />
                </div>
              );
            }

            return (
              <div className={classes.commentContainer} key={rating.name}>
                <QuestionBlock
                  title={translate(rating.title, { sequence: index + 1 })}
                >
                  <FormTextField
                    name={rating.name}
                    props={{
                      className: classes.commentArea,
                      fullWidth: true,
                      multiline: true,
                      rows: 4,
                    }}
                  />
                </QuestionBlock>
                <div className={classes.separator} />
              </div>
            );
          })}
        </div>
        <div className={classes.fullWidthContainer}>
          <div className="buttonArea">
            <Button
              className="button"
              color="secondaryLight"
              disabled={!formState.isValid}
              id="rating-submit"
              loading={loading}
              variant="rounded"
              type="submit"
            >
              <Typography variant="button">
                {translate('page.rating.send')}
              </Typography>
            </Button>
          </div>
        </div>
      </form>
      <Copyright reserveFill isPositionAbsolute={false} />
    </FormProvider>
  );
};

export default RatingPage;
