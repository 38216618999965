import { InputLabel } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

type LabelBlockProps = {
  htmlFor?: string;
  name: string;
};

const LabelBlock: React.FC<LabelBlockProps> = ({ htmlFor, name }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <div className={classes.labelContainer}>
      <InputLabel className="label" htmlFor={htmlFor}>
        {name}
      </InputLabel>
      <span className="mandatoryLabel">
        {translate('page.form.mandatoryLabel')}
      </span>
    </div>
  );
};

export default LabelBlock;
