import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    background: theme.palette.common.white,
    borderRadius: '50px',
    color: '#0057B8',
    fontSize: '0.875em',
    height: '48px',
    minWidth: '56px',
    width: '248px',
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '8px',
    margin: '0px 40px 40px 40px',
    width: '100%',
    [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
      flexWrap: 'nowrap',
      marginBottom: '16px',
    },
  },
  companyLogo: {
    [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
      height: '20px',
    },
  },
  contentContainer: {
    background: theme.palette.common.white,
    marginTop: '50px',
    [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
      margin: '16px 0px 16px 0px',
    },
    width: '100%',
  },
  dialogContainer: {
    background: theme.palette.common.white,
    width: '337px',
    [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
      width: '600px',
    },
  },
  errorMessageContainer: {
    margin: '0px 16px 24px 16px',
    '& >.svgContainer': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '50px',
      [`${theme.breakpoints.between(
        'xs',
        'sm'
      )} and (orientation: landscape)`]: {
        display: 'none',
      },
    },
    '& >.titleContainer': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '24px',
      [`${theme.breakpoints.between(
        'xs',
        'sm'
      )} and (orientation: landscape)`]: {
        '& >p': {
          whiteSpace: 'normal',
        },
      },
    },
    '& >.messageContainer': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
      '& >p': {
        fontWeight: 'normal',
      },
      [`${theme.breakpoints.between(
        'xs',
        'sm'
      )} and (orientation: landscape)`]: {
        margin: '24px 16px 16px 16px',
        '& >p': {
          whiteSpace: 'normal',
        },
      },
    },
  },
  paper: {
    alignItems: 'center',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 'fit-content',
    maxWidth: 'fit-content',
    width: '100%',
  },
}));
