import { ConnectOptions } from 'twilio-video';

export type EnvironmentConfig = {
  ENV: ENVS;
  SERVER_URL: string;
  SENTRY_DST: string;
  SENTRY_ENABLED: boolean;

  FIREBASE_CONFIG: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };

  TAG_MANAGER_CONTAINER_ID: string;

  TWILIO_CONNECT_OPTIONS: ConnectOptions;
};

export enum ENVS {
  DEVELOP = 'develop',
  STAGING = 'staging',
  PRODUCTION = 'production',
}
