import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  description: {
    color: theme.palette.common.white,
    fontSize: '0.875em',
    whiteSpace: 'break-spaces',
  },
  listItem: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  listItemIcon: {
    color: theme.palette.common.white,
    fontSize: '0.875em',
    alignItems: 'flex-start',
    marginLeft: '0.6em',
    marginTop: '-0.475em',
  },
  listItemText: {
    dense: true,
    marginLeft: '-3.25em',
    marginTop: 0,
    marginBottom: 0,
  },
}));
