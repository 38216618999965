import dedent from 'dedent';
import { Translation } from './types';

const jp: Translation = {
  common: {
    you: 'あなた',
    buttons: {
      cancel: 'キャンセル',
      camera: 'カメラ',
      endCall: '終了する',
      microphone: 'マイク',
      screenShare: '画面共有',
    },
    errors: {
      callEndConfirmation: {
        confirmClose: '本当に終了しますか？',
      },
      noMatch: {
        buttons: {
          anotherConsultation: '他の相談方法を探す',
          backToTop: 'オンライン相談トップに戻る',
        },
        message: dedent`誠に恐れ入りますが、現在全ての
          スタッフが対応中または営業時間
          （平日11:00〜18:00、土日祝11:00〜17:00）
          ではないため、お繋ぎできません。
          しばらくしてからもう一度お試しいただくか、
          下記より他の方法でご相談ください。`,
        title: '現在対応できる\nスタッフがいません。',
      },
      sessionEnd: {
        button: '戻る',
        message:
          'セッションが切れましたので\n再度、相談フォームにて入力して下さい。',
        title: 'セッションが切断されました。',
      },

      unknown: {
        button: '戻る',
        title: '予期せぬエラーが\n発生しました。',
      },
    },
  },
  component: {
    chatRoom: {
      inputPlaceholder: 'テキストを入力してください',
    },
    boardingPass: {
      name: 'お名前',
      furigana: 'お名前（フリガナ）',
      phone: '電話番号',
      origin: '出発エリア',
      destination: '行き先',
      confirm: '相談をはじめる',
      sessionDescription: dedent`30秒以内に開始しない場合、
      セッションが切れますのでご了承ください。`,
    },
  },
  page: {
    networkNotification: {
      notConnected: 'ネットワークが接続されていません。',
      connected: 'ネットワークに接続できました！',
      retry: 'リトライ',
      noConnection: '接続なし',
      lowConnection: '接続不良',
      highConnection: '接続済み',
    },
    video: {
      pleaseWait: '少々お待ちください',
      tips: 'TIPS',
      tipsCaption: 'マイクとカメラはオフになります。',
      cameraInstruction: '',
    },
    rating: {
      title: dedent`今回のご案内はいかがでしたか？

              サービス向上のため、アンケートへの
              ご協力をお願いいたします。

              下記項目への満足度を5段階で
              お聞かせください。`,
      send: '送信する',
      question: {
        attitude: {
          title: '【{{sequence}}】接客態度',
        },
        planning: {
          title: '【{{sequence}}】提案力',
        },
        knowledge: {
          title: '【{{sequence}}】知識の豊富さ',
        },
        usability: {
          title: '【{{sequence}}】操作のしやすさ',
        },
        quality: {
          title: '【{{sequence}}】映像や音声の質',
        },
        recommendation: {
          title: dedent`【{{sequence}}】オンラインによる旅行相談を
                  他の方にもすすめたいですか？`,
        },
        comment: {
          title: dedent`【{{sequence}}】その他、ご意見やご要望が
                  ありましたらお聞かせください。(任意入力)`,
        },
      },
    },
    selectRegion: {
      title: '相談はこちらから',
      selectOrigin: '出発エリアを選択してください',
      description: dedent`【オンライン相談（ビデオチャット）に関するご注意事項】

     ●本サービスは協力会社である株式会社Zealsが提供するビデオチャッ
     トシステムを使用した相談サービです。
     ●インターネットに接続する通信料はお客さまのご負担となります。
     ●ご利用のインターネットの回線速度やセキュリティによって
      画質の低下や通話切断、もしくは利用できない場合があります。
      なお、推奨環境は以下のとおりです。
      パソコンの場合：
      ・Windows（Google Chrome）
      ・MacOS（Google Chrome）
      スマートフォン・タブレットの場合：
      ・iPhone/iPad（Safari）
      ・Android（Google Chrome）
     ●スタッフの社員教育、品質向上のために通話を録画、チャットの内容を保存しております。`,
    },
    form: {
      description: '【オンライン相談（ビデオチャット）に関するご注意事項】\n',
      descriptionItem1:
        'インターネットに接続する通信料はお客さまのご負担となります。',
      descriptionItem2: dedent`ご利用のインターネットの回線速度やセキュリティによって
        画質の低下や通話切断、もしくは利用できない場合があります。`,
      descriptionItem3:
        'スタッフの社員教育、品質向上のために通話を録画、チャットの内容を保存しております。',
      fields: {
        name: {
          label: 'お名前',
          placeholder: '例：山田 太郎',
        },
        furigana: {
          errors: {
            validation: '全角カタカナで入力してください。',
          },
          label: 'お名前（フリガナ）',
          placeholder: '例：ヤマダ タロウ',
        },
        destination: {
          label: '行き先',
          group: {
            domestic: '国内',
            overseas: '海外',
          },
        },
        phone: {
          errors: {
            length: '電話番号が正しくありません。',
            validation: 'ハイフンなしで半角数字のみで入力してください。',
          },
          label: '電話番号',
          placeholder: '例：08012345678',
        },
      },
      mandatoryLabel: '必須',
      privacy: '<0>個人情報保護方針</0>に同意する',
      submit: '次へ',
      subTitle: '項目を確認し、正確にご入力ください。',
      title: '必要事項の入力',
    },
    thanks: {
      title: 'アンケートにご協力いただき、\nありがとうございました。',
      buttons: {
        home: 'HIS トップページヘ',
      },
    },
  },
  regions: {
    tbd: '決まってない',
    domestic: {
      hokkaido: '北海道',
      tohoku: '東北',
      chubu_hokuriku: '中部・北陸',
      chugoku_shikoku: '中国・四国',
      koshinetsu: '甲信越',
      kanto: '関東',
      toukai: '東海',
      hokuriku: '北陸',
      kansai: '関西',
      sanin_sanyo: '山陰山陽',
      shikoku: '四国',
      kyushu: '九州',
      okinawa: '沖縄',
      okinawa_islands: '沖縄離島',
    },
    overseas: {
      asia: 'アジア',
      america_canada_latin_america: 'アメリカ・カナダ・中南米',
      ocenia_south_pacific_palau: 'オセアニア・南太平洋・パラオ',
      hawaii: 'ハワイ',
      europe_near_and_middle_east_africa: 'ヨーロッパ・中近東・アフリカ',
    },
  },
};

export default jp;
