import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import firebase from 'firebase/app';

import {
  ErrorBoundry,
  PageViewMonitor,
  ScrollTop,
} from '@zeals/shared-components';

import Route from './Route';
import { useVendorConfig } from '../config';

import ChatVideoPage from '../pages/ChatVideoPage';
import RatingPage from '../pages/RatingPage';
import BoardingPassPage from '../pages/BoardingPassPage';
import FormPage from '../pages/FormPage';
import ThanksPage from '../pages/ThanksPage';
import UnknownErrorPage from '../pages/errors/UnknownError';

const Router: React.FC = () => {
  const vendorConfig = useVendorConfig();

  useEffect(() => {
    if (!vendorConfig) {
      return;
    }

    firebase.analytics().setUserProperties({
      app: 'enduser',
      vendor: vendorConfig.domain,
    });
  }, [vendorConfig]);

  if (!vendorConfig) {
    // TODO: add fallback?
    return null;
  }

  return (
    <BrowserRouter basename={`/${vendorConfig.domain}`}>
      <PageViewMonitor>
        <ScrollTop>
          <ErrorBoundry fallback={<UnknownErrorPage />}>
            <Switch>
              <Route path="/form" theme="light" privateRoute={false}>
                <FormPage />
              </Route>

              <Route exact path="/rooms/:roomId" privateRoute={false}>
                <BoardingPassPage />
              </Route>

              <Route path="/rooms/:roomId/video">
                <ChatVideoPage />
              </Route>

              <Route path="/rooms/:roomId/rating">
                <RatingPage />
              </Route>

              <Route path="/rooms/:roomId/thanks">
                <ThanksPage />
              </Route>
            </Switch>
          </ErrorBoundry>
        </ScrollTop>
      </PageViewMonitor>
    </BrowserRouter>
  );
};

export default Router;
