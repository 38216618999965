import { observable, action, runInAction } from 'mobx';
import { persist } from 'mobx-persist';

import { CreateEnduserRequest } from '@zeals/shared-types/build/api/enduser';
import { EnduserInstance } from '@zeals/shared-types/build/enduser';

import { RootStore } from './rootStore';
import api from '../services/api';

class AuthStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @persist @observable accessToken = '';

  @observable twilioAccessToken = '';

  @persist('object') @observable user: EnduserInstance = null;

  @action getCurrentUser = async (): Promise<void> => {
    if (this.fetching) return;

    this.fetching = true;

    try {
      // add the person to the conversation
      const response = await api.getCurrentUser();
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      if (!response.data.user) {
        throw new Error();
      }

      runInAction(() => {
        const { user } = response.data;
        this.user = user;
      });
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action setAccessToken = (token: string): void => {
    this.accessToken = token;
  };

  @action register = async (request: CreateEnduserRequest): Promise<void> => {
    if (this.fetching) return;

    this.fetching = true;

    try {
      // add the person to the conversation
      const response = await api.createEnduser(request);

      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      if (!response.data.accessToken) {
        throw new Error();
      }

      runInAction(() => {
        const { accessToken } = response.data;
        this.accessToken = accessToken;
      });
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action reset = (): void => {
    this.accessToken = null;
    this.user = null;
  };
}

export default AuthStore;
