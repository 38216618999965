import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Button, Spacer } from '@zeals/shared-components';

import LogoContainer from '../../../components/LogoContainer';

import useStyles from './styles';
import { useVendorConfig } from '../../../config';
import { HISVendorConfig } from '../../../config/vendors/his';

type SelectRegionBlockProps = {
  onSelect: (region: string) => void;
};

const SelectRegionBlock: React.FC<SelectRegionBlockProps> = ({
  onSelect,
}: SelectRegionBlockProps) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const vendorConfig = useVendorConfig<HISVendorConfig>();

  return (
    <LogoContainer company="his">
      <Grid className={classes.contentContainer} container item spacing={4}>
        <Grid item>
          <Typography variant="h4" className={classes.text}>
            <b>{translate('page.selectRegion.title')}</b>
          </Typography>
        </Grid>
        <Grid item>
          <Spacer size="l" />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.text}>
            {translate('page.selectRegion.selectOrigin')}
          </Typography>
        </Grid>
        <Grid
          alignItems="center"
          className="buttonContainer"
          container
          direction="row"
          spacing={2}
        >
          {Object.values(vendorConfig.ORIGIN).map((region) => {
            return (
              <Grid item className={classes.region} key={region}>
                <Button
                  id={`region-${region}`}
                  className="regionButton"
                  onClick={() => {
                    onSelect(region);
                  }}
                >
                  {translate(`regions.domestic.${region}`)}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid item>
          <Typography
            className={['description', classes.text].join(' ')}
            variant="body1"
          >
            {translate('page.selectRegion.description')}
          </Typography>
        </Grid>
      </Grid>
    </LogoContainer>
  );
};

export default SelectRegionBlock;
