import { action, observable } from 'mobx';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';
import { SendFeedbackRequest } from '@zeals/shared-types/build/api/reservation';

import { RootStore } from './rootStore';
import api from '../services/api';

class ReservationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @action sendFeedback = async (
    request: SendFeedbackRequest
  ): Promise<ApiResponseWrapper<void>> => {
    const response = await api.sendFeedback(request);
    return response;
  };
}

export default ReservationStore;
