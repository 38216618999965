import { ENVS, EnvironmentConfig } from './types';

const develop: EnvironmentConfig = {
  ENV: ENVS.DEVELOP,
  SERVER_URL:
    process.env.REACT_APP_SERVER_URL || 'https://api.dev.omotenashi.ai/v1',
  SENTRY_DST:
    'https://0214400137c8405889da21eadbf7842a@o247680.ingest.sentry.io/5544232',
  SENTRY_ENABLED: process.env.REACT_APP_SENTRY_ENABLED === 'true' || false,
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyCa9BR8qH89sU0XiXcEjGI-8OdrTWvOCh4',
    authDomain: 'zeals-dx-dev.firebaseapp.com',
    databaseURL: 'https://zeals-dx-dev.firebaseio.com',
    projectId: 'zeals-dx-dev',
    storageBucket: 'zeals-dx-dev.appspot.com',
    messagingSenderId: '603262912759',
    appId: '1:603262912759:web:4b04d58dd1f12a2a15ebf2',
    measurementId: 'G-TL8W5FSGH1',
  },
  TAG_MANAGER_CONTAINER_ID: 'GTM-MRQPF7Q',
  TWILIO_CONNECT_OPTIONS: {
    tracks: [],
    maxAudioBitrate: 16000,
    /**
     * @see {@link https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#mobile-browser-grid-recommended-settings}
     *
     * @privateRemark
     * Because `bandwidthProfile` and `networkQuality` are unavailable
     * for "go" and "p2p" rooms, the options below are only for testing.
     * @see {@link @zeals/api/src/routes/enduser/matching}
     */
    bandwidthProfile: {
      video: {
        mode: 'presentation',
        maxTracks: 6, // the recommendation is 4 but since we have to switch some tracks between so having some buffer here
        maxSubscriptionBitrate: 2500000,
        dominantSpeakerPriority: 'standard',
        renderDimensions: {
          high: { height: 1080, width: 1920 },
          /**
           * @privateRemark
           * We want portrait view as default,
           * @see {@link @zeals/shared-components/hooks/useLocalTracks#DEFAULT_VIDEO_OPTIONS},
           * hence the uncommon resolution of `standard`.
           * Please note that it will consume more CPU power than usual.
           */
          standard: { height: 640, width: 360 },
        },
      },
    },
    dominantSpeaker: true,
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    /**
     * @privateRemark
     * For now we probably need Sentry's help to get detailed info of end-user side,
     * therefore admin and end-user's pairs of verbosity here are identical.
     * To be more specific, local participant is responsible for collecting all
     * available info through the highest verbosity, and remote participants only
     * report their identities and signal strengths for reference.
     * If we don't need Sentry to collect info for us, then end-user side verbosity
     * can be minimal or even unregistered, such that NQ API won't consume bandwidth
     * on end-user side.
     */
    networkQuality: { local: 3, remote: 1 },
  },
};

export default develop;
