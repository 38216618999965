import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';

import { GoogleTagManager } from '@zeals/shared-components';

import config, { useVendorConfig } from './config';
import { ENVS } from './config/environments/types';
import { HISVendorConfig } from './config/vendors/his';
import { useStores, hydrateAll, RootStoreContext } from './stores';
import i18n from './i18n';

import Router from './navigations/Router';
import SentryWrapper from './navigations/SentryWrapper';

import './assets/style/app.css';

i18n();

firebase.initializeApp(config.FIREBASE_CONFIG);

const App = (): React.ReactElement => {
  const [hydrated, setHydrated] = useState(false);
  const rootStore = useStores();
  const vendorConfig = useVendorConfig<HISVendorConfig>();

  if (!hydrated) {
    hydrateAll(rootStore).then(() => setHydrated(true));
    return null;
  }

  // temp solution to only enable his gtm for production
  if (
    config.ENV === ENVS.PRODUCTION &&
    vendorConfig &&
    vendorConfig.GOOGLE_TAG_MANAGER_ID
  ) {
    return (
      <GoogleTagManager containerId={config.TAG_MANAGER_CONTAINER_ID}>
        <GoogleTagManager containerId={vendorConfig.GOOGLE_TAG_MANAGER_ID}>
          <SentryWrapper>
            <RootStoreContext.Provider value={rootStore}>
              <Router />
            </RootStoreContext.Provider>
          </SentryWrapper>
        </GoogleTagManager>
      </GoogleTagManager>
    );
  }

  return (
    <GoogleTagManager containerId={config.TAG_MANAGER_CONTAINER_ID}>
      <SentryWrapper>
        <RootStoreContext.Provider value={rootStore}>
          <Router />
        </RootStoreContext.Provider>
      </SentryWrapper>
    </GoogleTagManager>
  );
};

export default App;
