import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    fullWidthContainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: '100%',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
  };
});
