import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  header: {
    alignItems: 'flex-start',
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(4),
    width: '100%',
    '& >.companyLogo': {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
  },
}));
