import { makeStyles } from '@material-ui/core/styles';
import { isIOS } from 'react-device-detect';

export default makeStyles(() => ({
  container: {
    height: '100%',
    minHeight: isIOS ? '-webkit-fill-available' : '100vh',
    maxHeight: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  loadingText: {
    flexGrow: 1,
  },
}));
