import { makeStyles } from '@material-ui/core/styles';
import { isIOS } from 'react-device-detect';

export default makeStyles((theme) => {
  return {
    boardingPassContainer: {
      height: '100%',
      minHeight: isIOS ? '-webkit-fill-available' : '100vh',
      maxHeight: '100%',
      maxWidth: '100%',
      margin: 0,
    },
    boardingPass: {
      maxWidth: 'fit-content',
      padding: theme.spacing(4),
      width: '100%',
    },
  };
});
