import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import useStyles from './styles';

const Precautions: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <>
      <Typography className={classes.description}>
        {translate('page.form.description')}
      </Typography>
      <List dense className={classes.description}>
        <ListItem
          className={classes.listItem}
          alignItems="flex-start"
          dense
          disableGutters
        >
          <ListItemIcon>
            <FiberManualRecordIcon className={classes.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={
              <Typography className={classes.description}>
                {translate('page.form.descriptionItem1')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          className={classes.listItem}
          alignItems="flex-start"
          dense
          disableGutters
        >
          <ListItemIcon>
            <FiberManualRecordIcon className={classes.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={
              <Typography className={classes.description}>
                {translate('page.form.descriptionItem2')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          className={classes.listItem}
          alignItems="flex-start"
          dense
          disableGutters
        >
          <ListItemIcon>
            <FiberManualRecordIcon className={classes.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={
              <Typography className={classes.description}>
                {translate('page.form.descriptionItem3')}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </>
  );
};

export default Precautions;
