import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import firebase from 'firebase/app';
import {
  Dialog,
  ErrorMessage,
  HotAirBalloonSVG,
} from '@zeals/shared-components';
import useStyles from './styles';

type UnknownErrorPageType = {
  errorStatus?: number;
  origin?: string;
};

const UnknownErrorPage: React.FC<UnknownErrorPageType> = ({
  errorStatus = 0,
  origin,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const hotAirBalloonSVG = <HotAirBalloonSVG />;
  const { t: translate } = useTranslation();

  useEffect(() => {
    // Prevent recording empty data to GA
    if (errorStatus === 0 || !origin) {
      return;
    }

    const { logEvent } = firebase.analytics();
    logEvent('unknownError', { errorStatus, origin });
  }, [errorStatus]);

  return (
    <div
      className={classes.container}
      data-error-origin={origin}
      data-error-status={errorStatus}
    >
      <div className={classes.dialogContainer}>
        <Dialog
          header={{
            className: classes.header,
          }}
          body={{
            children: (
              <ErrorMessage
                svg={hotAirBalloonSVG}
                title={translate('common.errors.unknown.title')}
              />
            ),
          }}
          buttons={{
            children: (
              <div className={classes.buttonsContainer}>
                <Button
                  id="unknown-error-confirm"
                  className={classes.button}
                  color="primary"
                  fullWidth
                  onClick={() => history.go(0)}
                  variant="outlined"
                >
                  {translate('common.errors.unknown.button')}
                </Button>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default UnknownErrorPage;
