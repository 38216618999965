import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Rating, RatingProps } from '@material-ui/lab';
import { RulesProps } from '@zeals/shared-components';

type RatingBlockProps = {
  name: string;
  props?: RatingProps;
  rules?: RulesProps;
};

const RatingBlock: React.FC<RatingBlockProps> = ({
  name,
  props,
  rules,
}: RatingBlockProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      defaultValue={0}
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, ...rest }) => (
        <Rating
          name={`${name}-rating`}
          {...rest}
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(Number(e.target.value))
          }
        />
      )}
    />
  );
};

export default RatingBlock;
