import { observable, action, runInAction } from 'mobx';
import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';
import { ProcessMatchingResponse } from '@zeals/shared-types/build/api/matching';

import {
  GetConversationTokenRequest,
  GetConversationTokenResponse,
} from '@zeals/shared-types/build/api/conversation';
import { RootStore } from './rootStore';
import api from '../services/api';

class ConversationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @action getConversationToken = async (
    request: GetConversationTokenRequest
  ): Promise<ApiResponseWrapper<GetConversationTokenResponse>> => {
    if (this.fetching) return null;

    this.fetching = true;

    try {
      // add the person to the conversation
      const response = await api.getConversationToken(request);
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      return response;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action processMatching = async (): Promise<
    ApiResponseWrapper<ProcessMatchingResponse>
  > => {
    if (this.fetching) return null;

    this.fetching = true;

    try {
      // add the person to the conversation
      const response = await api.processMatching();
      return response;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };
}

export default ConversationStore;
