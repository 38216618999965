import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Select, SelectProps } from '@material-ui/core';

import { useVendorConfig } from '../../../../config';
import { HISVendorConfig } from '../../../../config/vendors/his';

type FormNativeSelectProps = {
  label?: string;
  name: string;
  props?: SelectProps;
};

const FormNativeSelect: React.FC<FormNativeSelectProps> = ({
  label,
  name,
  props,
}: FormNativeSelectProps) => {
  const { control } = useFormContext();
  const { t: translate } = useTranslation();
  const vendorConfig = useVendorConfig<HISVendorConfig>();

  return (
    <>
      <Controller
        as={
          <Select native id="select-destination" {...props}>
            <option value={vendorConfig.DESTINATION.TBD}>
              {translate(`regions.${vendorConfig.DESTINATION.TBD}`)}
            </option>
            <optgroup
              label={translate('page.form.fields.destination.group.domestic')}
            >
              {Object.values(vendorConfig.DESTINATION.DOMESTIC).map((val) => {
                return (
                  <option key={val} value={val}>
                    {translate(`regions.domestic.${val}`)}
                  </option>
                );
              })}
            </optgroup>
            <optgroup
              label={translate('page.form.fields.destination.group.overseas')}
            >
              {Object.values(vendorConfig.DESTINATION.OVERSEAS).map((val) => {
                return (
                  <option key={val} value={val}>
                    {translate(`regions.overseas.${val}`)}
                  </option>
                );
              })}
            </optgroup>
          </Select>
        }
        control={control}
        defaultValue={vendorConfig.DESTINATION.TBD}
        label={label}
        name={name}
      />
    </>
  );
};

export default FormNativeSelect;
