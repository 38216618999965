import React from 'react';

type PocketSVGProps = {
  style?: React.CSSProperties;
  className?: string;
};

const PocketSVG: React.FC<PocketSVGProps> = ({
  style = {},
  className,
}: PocketSVGProps) => {
  return (
    <svg
      width={116}
      height={116}
      viewBox="0 0 116 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M16.313 30.813h83.374a7.25 7.25 0 017.251 7.25v52.562a5.437 5.437 0 01-5.438 5.438h-87a5.438 5.438 0 01-5.438-5.438V38.062a7.25 7.25 0 017.25-7.25z"
        fill="#348FD9"
      />
      <path
        d="M101.5 88.813V31.069a7.19 7.19 0 00-1.813-.256h-1.812v58h3.625zM9.063 85.188h9.062v3.624H9.062v-3.624z"
        fill="#3086CD"
      />
      <path d="M97.875 85.188h9.063v3.624h-9.063v-3.624z" fill="#3086CD" />
      <path
        d="M97.875 21.75a7.25 7.25 0 00-7.25-7.25h-65.25a7.25 7.25 0 00-7.25 7.25v70.688h79.75V21.75z"
        fill="#FFB531"
      />
      <path
        d="M97.875 21.75a7.25 7.25 0 00-7.25-7.25h-6.269l-54.94 77.938h68.459V21.75z"
        fill="#FFA912"
      />
      <path d="M18.125 85.188h79.75v9.062h-79.75v-9.063z" fill="#FFA912" />
      <path
        d="M25.375 77.938h65.25v3.624h-65.25v-3.624zM67.063 59.58L54.374 38.063h-5.438l5.438 17.93-14.5-3.586-1.813-5.282-5.437-1.813V59.58L77.73 72.329a4.418 4.418 0 005.645-4.212 4.385 4.385 0 00-3.028-4.157l-13.284-4.38z"
        fill="#4D5E78"
      />
      <path
        d="M25.373 21.75h3.625v3.625h-3.625V21.75zM32.623 21.75h3.625v3.625h-3.625V21.75zM39.873 21.75h3.625v3.625h-3.625V21.75zM25.375 29h65.25v3.625h-65.25V29z"
        fill="#F79D16"
      />
      <path
        d="M25.375 36.25h7.25v3.625h-7.25V36.25zM36.25 36.25h7.25v3.625h-7.25V36.25zM61.625 36.25h7.25v3.625h-7.25V36.25zM72.5 36.25h7.25v3.625H72.5V36.25zM83.375 36.25h7.25v3.625h-7.25V36.25z"
        fill="#4D5E78"
      />
      <path
        d="M68.875 90.625a6.189 6.189 0 01-4.376 1.813H51.501a6.189 6.189 0 01-4.376-1.813 6.189 6.189 0 00-4.376-1.813H5.437v5.438a5.437 5.437 0 005.438 5.438h94.25a5.439 5.439 0 005.437-5.438v-5.438H73.251a6.189 6.189 0 00-4.376 1.813z"
        fill="#E0E0DE"
      />
      <path
        d="M99.688 88.813v5.437a5.438 5.438 0 01-5.438 5.438h10.875a5.439 5.439 0 005.437-5.438v-5.438H99.688z"
        fill="#D6D6D4"
      />
    </svg>
  );
};

export default PocketSVG;
