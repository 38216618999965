import { makeStyles } from '@material-ui/core/styles';
import { isIOS } from 'react-device-detect';

export default makeStyles((theme) => ({
  button: {
    borderRadius: '50px',
  },
  buttonsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    maxWidth: '289px',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    maxHeight: '100%',
    maxWidth: '100%',
    minHeight: isIOS ? '-webkit-fill-available' : '100vh',
  },
  dialogContainer: {
    borderRadius: '30px',
    height: 'fit-content',
    width: '350px',
  },
  header: {
    background: theme.palette.common.white,
  },
}));
