import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { BoardingPass, CompanyLogo, Copyright } from '@zeals/shared-components';

import { useStores } from '../../stores';

import useStyles from './styles';

type BoardingPassParams = {
  roomId: string;
};

const BoardingPassPage: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { authStore } = useStores();
  const { roomId } = useParams<BoardingPassParams>();
  const query = qs.parse(location.search.slice(1));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (query.token) {
      const token = query.token as string;
      authStore.setAccessToken(token);
    }

    authStore.getCurrentUser().then(() => setLoading(false));
  }, [authStore, query]);

  if (loading) {
    return null;
  }

  return (
    <Grid
      className={classes.boardingPassContainer}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        className={classes.boardingPass}
      >
        <BoardingPass
          showSessionDescription
          translate={translate}
          closeButtonText={translate('component.boardingPass.confirm')}
          destination={
            translate(
              `regions.domestic.${authStore.user.metadata.destination}`
            ) ||
            translate(
              `regions.overseas.${authStore.user.metadata.destination}`
            ) ||
            translate(`regions.${authStore.user.metadata.destination}`)
          }
          furigana={authStore.user.metadata.furigana}
          phone={authStore.user.phone}
          logo={<CompanyLogo company="his" reserveFill />}
          name={authStore.user.name}
          onConfirm={() => {
            history.push(`/rooms/${roomId}/video`);
          }}
          origin={translate(
            `regions.domestic.${authStore.user.metadata.region}`
          )}
        />
        <Copyright reserveFill isPositionAbsolute={false} />
      </Grid>
    </Grid>
  );
};

export default BoardingPassPage;
