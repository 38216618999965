import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  useTheme,
} from '@material-ui/core';
import { RulesProps } from '@zeals/shared-components';

type FormCheckboxProps = {
  formControlProps?: FormControlLabelProps;
  label?: React.ReactNode;
  name: string;
  props?: CheckboxProps;
  rules?: RulesProps;
};

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  formControlProps,
  label,
  name,
  props,
  rules,
}: FormCheckboxProps) => {
  const { register } = useFormContext();
  const theme = useTheme();

  return (
    <>
      <FormControlLabel
        style={{ color: theme.palette.common.white }}
        control={
          <Checkbox inputProps={{ 'aria-label': 'checkbox' }} {...props} />
        }
        label={label}
        name={name}
        inputRef={register(rules)}
        {...formControlProps}
      />
    </>
  );
};

export default FormCheckbox;
