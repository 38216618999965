import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import { Button, Copyright } from '@zeals/shared-components';

import PocketSVG from './PocketSVG';

import useStyles from './styles';

const ThanksPage: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <Grid
      className={classes.container}
      container
      spacing={4}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <PocketSVG />
      </Grid>

      <Grid item>
        <Typography
          className={classes.title}
          color="textSecondary"
          variant="h6"
          align="center"
        >
          {translate('page.thanks.title')}
        </Typography>
      </Grid>

      <Grid item className={classes.homeButtonContainer}>
        <Button
          id="home-button"
          color="secondary"
          variant="rounded"
          onClick={() => {
            window.location.href = 'https://www.his-j.com/Default.aspx';
          }}
        >
          {translate('page.thanks.buttons.home')}
        </Button>
      </Grid>
      <Copyright reserveFill isPositionAbsolute />
    </Grid>
  );
};

export default ThanksPage;
