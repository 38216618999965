import React from 'react';
import {
  Route as RRDRoute,
  RouteProps as RRDRouteProps,
  Redirect,
} from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { observer } from 'mobx-react';

import { dark, light } from '../assets/theme';
import { useStores } from '../stores';

export type RouteProps = RRDRouteProps & {
  theme?: 'light' | 'dark';
  privateRoute?: boolean;
};

const Route: React.FC<RouteProps> = ({
  theme = 'dark',
  privateRoute = true,
  ...props
}) => {
  const { authStore } = useStores();

  if (privateRoute) {
    if (!authStore.accessToken || !authStore.user) {
      return (
        <RRDRoute
          render={({ location }) => {
            return (
              <Redirect
                push
                to={{
                  pathname: '/form/region',
                  state: { from: location },
                }}
              />
            );
          }}
        />
      );
    }
  }

  return (
    <ThemeProvider theme={theme === 'dark' ? dark : light}>
      <CssBaseline />
      <RRDRoute {...props} />
    </ThemeProvider>
  );
};

export default observer(Route);
