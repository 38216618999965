import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog as Modal } from '@material-ui/core';
import firebase from 'firebase/app';
import {
  CompanyLogo,
  Dialog,
  ErrorMessage,
  NoMatchSVG,
} from '@zeals/shared-components';
import Link from '@material-ui/core/Link';
import useStyles from './styles';

type NoMatchErrorModalProps = {
  dataErrorType: string;
  open?: boolean;
};

const NoMatchErrorModal: React.FC<NoMatchErrorModalProps> = ({
  dataErrorType,
  open = false,
}) => {
  const classes = useStyles();
  const noMatchSVG = <NoMatchSVG />;
  const { t: translate } = useTranslation();

  useEffect(() => {
    // Prevent recording empty data to GA
    if (!dataErrorType) {
      return;
    }

    const { logEvent } = firebase.analytics();
    logEvent(dataErrorType);
  }, [dataErrorType]);

  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      open={open}
    >
      <div className={classes.dialogContainer} data-error-type={dataErrorType}>
        <Dialog
          header={{
            logo: (
              <CompanyLogo
                className={classes.companyLogo}
                company="his"
                reserveFill
                scale={2}
              />
            ),
          }}
          body={{
            className: classes.contentContainer,
            children: (
              <ErrorMessage
                className={classes.errorMessageContainer}
                message={translate('common.errors.noMatch.message')}
                svg={noMatchSVG}
                title={translate('common.errors.noMatch.title')}
              />
            ),
          }}
          buttons={{
            children: (
              <div className={classes.buttonsContainer}>
                <Button
                  id="another-consultation"
                  className={classes.button}
                  color="primary"
                  fullWidth
                  variant="outlined"
                >
                  <Link
                    href="https://www.his-j.com/support/guide/userguide.html"
                    underline="none"
                    rel="noopener noreferrer"
                  >
                    {translate(
                      'common.errors.noMatch.buttons.anotherConsultation'
                    )}
                  </Link>
                </Button>
                <Button
                  id="back-to-top"
                  className={classes.button}
                  color="primary"
                  variant="outlined"
                >
                  <Link
                    href="https://www.his-j.com/info/videochat/index.html"
                    underline="none"
                    rel="noopener noreferrer"
                  >
                    {translate('common.errors.noMatch.buttons.backToTop')}
                  </Link>
                </Button>
              </div>
            ),
          }}
        />
      </div>
    </Modal>
  );
};

export default NoMatchErrorModal;
