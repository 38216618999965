import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

type QuestionBlockProps = {
  children: React.ReactNode;
  description?: string;
  title: string;
};

const QuestionBlock: React.FC<QuestionBlockProps> = ({
  children,
  description = null,
  title,
}: QuestionBlockProps) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      className={classes.fullWidthContainer}
      container
      direction="column"
      justify="center"
    >
      <div>
        <Typography
          className={classes.textAlignCenter}
          color="textSecondary"
          variant="subtitle1"
        >
          {title}
        </Typography>
      </div>

      {description && (
        <div>
          <Typography
            className={classes.textAlignCenter}
            color="textSecondary"
            variant="body1"
          >
            {description}
          </Typography>
        </div>
      )}

      <div
        className={`${classes.fullWidthContainer} ${classes.textAlignCenter}`}
      >
        {children}
      </div>
    </Grid>
  );
};

export default QuestionBlock;
