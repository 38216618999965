import { isIOS } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    background: theme.palette.primary.light,
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoRows: 'min-content 1fr',
    height: '100%',
    minHeight: isIOS ? '-webkit-fill-available' : '100vh',
    maxHeight: '100%',
    maxWidth: '100%',
    margin: 0,
    overflow: 'hidden',
    '& >.header': {
      gridRow: 1,
      width: '100%',
    },
    '& >.content': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
  },
}));
