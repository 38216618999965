import { makeStyles } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';
import { isIOS } from 'react-device-detect';

export default makeStyles((theme) => ({
  rootContainer: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'minmax(0, auto) minmax(0, 1fr) minmax(0, auto)',
    minHeight: isIOS ? '-webkit-fill-available' : '100vh',
    maxHeight: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    width: '100%',
    '& >.topRow': {
      display: 'inline-flex',
      flexDirection: 'column',
      position: 'relative',
      zIndex: 1,
      [theme.breakpoints.between('xs', 'xl')]: {
        height: '27vh',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        height: '37vh',
      },
      '& >.statusBarContainer': {
        background: rgba(theme.palette.common.black, 0.8),
        borderRadius: '16px',
        display: 'flex',
        marginTop: '8px',
        padding: '5px 10px',
        width: '100%',
        '&>.statusBar': {
          display: 'flex',
          marginLeft: '16px',
          marginRight: '16px',
          width: '100%',
          justifyContent: 'space-between',
        },
      },
      '& >.localVideoContainer': {
        display: 'flex',
        flexBasis: '100%',
        flexGrow: 1,
        justifyContent: 'flex-end',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        position: 'relative',
        width: '100%',
        '&> .alertContainer': {
          position: 'absolute',
          width: '100%',
          zIndex: 1,
        },
      },
    },
    '& >.chat': {
      alignContent: 'end',
      display: 'grid',
      gridRow: 2,
      zIndex: 1,
    },
    '& >.controls': {
      background: '#434343',
      display: 'grid',
      gridRow: 3,
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      zIndex: 1,
      '& >button:hover': {
        background: '#565656',
      },
    },
  },
  localVideoTag: {
    borderRadius: '10px',
    height: '100%',
    right: 0,
    top: 0,
    transform: 'rotateY(180deg)',
    '-webkit-transform': 'rotateY(180deg)' /* Safari and Chrome */,
    '-moz-transform': 'rotateY(180deg)' /* Firefox */,
    width: 'auto',
  },
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  remoteVideoContainer: {
    height: '100%',
    left: 0,
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    width: '100%',
    background: 'linear-gradient(0deg, #242424, #242424)',
  },
  remoteVideoTag: {
    height: '100%',
    left: '50%',
    margin: 'auto',
    transform: 'translateX(-50%)',
    '-webkit-transform': 'translateX(-50%)',
    width: '100%',
  },
  badNetworkIcon: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
}));
