import React, { ReactNode } from 'react';
import { Link } from '@material-ui/core';
import { CompanyLogoData } from '@zeals/shared-components';
import useStyles from './styles';

import CompanyHeaderBlock from './CompanyHeader';

type LogoContainerProps = {
  children: ReactNode;
  company?: CompanyLogoData.CompanyLogoTypes.Company;
};

const LogoContainer: React.FC<LogoContainerProps> = ({ children, company }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="header">
        <Link
          href="https://www.his-j.com/Default.aspx"
          underline="none"
          rel="noopener noreferrer"
        >
          <CompanyHeaderBlock company={company} />
        </Link>
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default LogoContainer;
