import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  contentContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: theme.spacing(3),
    margin: 0,
    maxWidth: '600px',
    width: '100%',
    '& >.buttonContainer': {
      padding: theme.spacing(3),
    },
    '& >div>.description': {
      fontSize: '0.875em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.7em',
      },
      whiteSpace: 'break-spaces',
    },
  },
  region: {
    width: '50%',
    '& >.regionButton': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      height: '90px',
      '&:hover': {
        background: theme.palette.success.main,
        color: theme.palette.common.white,
      },
    },
  },
  text: {
    color: theme.palette.common.white,
  },
}));
