import { ENVS, EnvironmentConfig } from './types';

const production: EnvironmentConfig = {
  ENV: ENVS.PRODUCTION,
  SERVER_URL: 'https://api.omotenashi.ai/v1',
  SENTRY_DST:
    'https://0214400137c8405889da21eadbf7842a@o247680.ingest.sentry.io/5544232',
  SENTRY_ENABLED: true,
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyDXC8SIfng-JyxwyCnv6UeianoFxoQED7g',
    authDomain: 'zeals-dx-prd.firebaseapp.com',
    databaseURL: 'https://zeals-dx-prd.firebaseio.com',
    projectId: 'zeals-dx-prd',
    storageBucket: 'zeals-dx-prd.appspot.com',
    messagingSenderId: '129733590022',
    appId: '1:129733590022:web:f7926845586f8f28c35787',
    measurementId: 'G-YBV6G9D773',
  },
  TAG_MANAGER_CONTAINER_ID: 'GTM-KMDRXDZ',
  TWILIO_CONNECT_OPTIONS: {
    tracks: [],
    maxAudioBitrate: 16000,
    // https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#mobile-browser-grid-recommended-settings
    bandwidthProfile: {
      video: {
        mode: 'presentation',
        maxTracks: 6, // the recommendation is 4 but since we have to switch some tracks between so having some buffer here
        maxSubscriptionBitrate: 2500000,
        dominantSpeakerPriority: 'standard',
        renderDimensions: {
          high: { height: 1080, width: 1920 },
          standard: { height: 640, width: 360 },
        },
      },
    },
    dominantSpeaker: true,
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    networkQuality: { local: 3, remote: 1 },
  },
};

export default production;
