import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import * as Sentry from '@sentry/react';

import qs from 'qs';
import firebase from 'firebase/app';
import {
  Grid,
  Typography,
  Link,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import { Button, FormTextField } from '@zeals/shared-components';
import {
  api as apiTypes,
  matching as matchingTypes,
} from '@zeals/shared-types';

import { useVendorConfig } from '../../config';
import { useStores } from '../../stores';

import useStyles from './styles';

import FormCheckbox from './controls/Checkbox';
import FormNativeSelectBlock from './controls/NativeSelect';
import FormSelectBlock from './controls/Select';
import LabelBlock from './Label/LabelBlock';
import LogoContainer from '../../components/LogoContainer/LogoContainer';
import NoMatchErrorModal from '../../components/modals/NoMatch';
import UnknownErrorPage from '../errors/UnknownError';
import SelectRegionBlock from './SelectRegionBlock';
import { HISVendorConfig } from '../../config/vendors/his';
import Precautions from './Precautions';

const FormPage: React.FC = () => {
  const methods = useForm({
    mode: 'onChange',
  });

  const { errors, formState, handleSubmit } = methods;
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { authStore, conversationStore, matchingStore } = useStores();
  const vendorConfig = useVendorConfig<HISVendorConfig>();
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search.slice(1));

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorStatus, setErrorStatus] = useState(apiTypes.error.GrpcStatus.OK);
  const [errorType, setErrorType] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [origin, setOrigin] = useState(query.origin);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (query.origin === 'chukoku_shikoku') {
      history.replace({
        pathname: location.pathname,
        search: `origin=${vendorConfig.ORIGIN.CHUGOKU_SHIKOKU}`,
      });
      return;
    }

    const regionValues = Object.values(vendorConfig.ORIGIN);

    if (!regionValues.includes(query.origin) && query.origin) {
      history.push({
        pathname: location.pathname,
      });
      return;
    }

    if (query.origin !== origin) {
      setOrigin(query.origin);
    }
  }, [vendorConfig.ORIGIN, query.origin, origin, history, location]);

  useEffect(() => {
    // skip checking if it's a region that does not exist
    const regionValues = Object.values(vendorConfig.ORIGIN);
    if (!origin || !regionValues.includes(origin)) return;

    const checkAvailability = async () => {
      setLoading(true);
      const response = await matchingStore.listRooms({
        filter: {
          status: matchingTypes.RoomStatus.AWAITING_MATCHING,
          vendorId: vendorConfig.domain,
          labels: {
            region: origin,
          },
        },
      });

      if (response.error) {
        if (response.error.status === apiTypes.error.GrpcStatus.NOT_FOUND) {
          setErrorType('load_notFound');
          setErrorDialog(true);
        } else {
          setErrorType(`load_errorStatus_${response.error.status}`);
          setErrorStatus(response.error.status);
        }
      }

      setLoading(false);
    };

    checkAvailability();
  }, [matchingStore, vendorConfig, origin, vendorConfig.ORIGIN]);

  useEffect(() => {
    // reset dialog when the path changed
    setErrorDialog(false);
    setErrorStatus(apiTypes.error.GrpcStatus.OK);
  }, [query.origin]);

  const onSubmit = (data: Record<string, string>) => {
    const { destination, furigana, name, phone } = data;

    Sentry.setUser({
      username: name,
      ip_address: '{{auto}}',
    });
    Sentry.setTag('region', origin);

    Promise.resolve().then(async () => {
      setSubmitting(true);
      await authStore
        .register({
          name,
          phone,
          domain: vendorConfig.domain,
          metadata: {
            furigana,
            region: origin,
            destination,
          },
        })
        .catch((err) => {
          setSubmitting(false);
          throw new Error(err);
        });

      firebase.analytics().setUserProperties({
        name,
        phone,
        origin,
        destination,
      });

      // searching for match
      const response = await conversationStore.processMatching();
      if (response.error) {
        const { status } = response.error;
        if (
          status === apiTypes.error.GrpcStatus.OK ||
          status === apiTypes.error.GrpcStatus.NOT_FOUND
        ) {
          setErrorType(`register_badStatus_${status}`);
          setErrorDialog(true);
          setSubmitting(false);
          return;
        }

        setErrorStatus(status);
        return;
      }

      const { room } = response.data;
      if (!room) {
        setErrorType('register_noRoom');
        setErrorDialog(true);
        setSubmitting(false);
        return;
      }

      const q = qs.stringify({
        token: authStore.accessToken,
      });

      // redirect user to waiting room
      history.push(`/rooms/${room.id}?${q}`);
    });
  };

  if (errorStatus !== apiTypes.error.GrpcStatus.OK) {
    return (
      <UnknownErrorPage errorStatus={errorStatus} origin="EndUserFormPage" />
    );
  }

  if (!origin) {
    return (
      <SelectRegionBlock
        onSelect={(region) => {
          history.push({
            pathname: location.pathname,
            search: qs.stringify({ origin: region }),
          });
        }}
      />
    );
  }

  if (loading) {
    return (
      <LogoContainer company="his">
        <div className={classes.loadingIndicatorContainer}>
          <CircularProgress color="secondary" />
        </div>
      </LogoContainer>
    );
  }

  return (
    <>
      <LogoContainer company="his">
        <div className={classes.contentContainer}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={4}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography className={classes.title} color="primary">
                    {translate('page.form.title')}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.subTitle} color="primary">
                    {translate('page.form.subTitle')}
                  </Typography>
                </Grid>

                <Grid item className={classes.inputFieldContainer}>
                  <LabelBlock
                    htmlFor="name"
                    name={translate('page.form.fields.name.label')}
                  />
                  <FormTextField
                    error={errors.name}
                    errorClassName={classes.error}
                    name="name"
                    props={{
                      fullWidth: true,
                      InputLabelProps: { shrink: false },
                      InputProps: { className: classes.inputField },
                      placeholder: translate(
                        'page.form.fields.name.placeholder'
                      ),
                    }}
                    rules={{
                      maxLength: 50,
                      required: true,
                    }}
                  />
                </Grid>

                <Grid item className={classes.inputFieldContainer}>
                  <LabelBlock
                    htmlFor="furigana"
                    name={translate('page.form.fields.furigana.label')}
                  />
                  <FormTextField
                    error={errors.furigana}
                    errorClassName={classes.error}
                    name="furigana"
                    props={{
                      fullWidth: true,
                      InputLabelProps: { shrink: false },
                      InputProps: { className: classes.inputField },
                      placeholder: translate(
                        'page.form.fields.furigana.placeholder'
                      ),
                    }}
                    rules={{
                      maxLength: 50,
                      pattern: {
                        message: translate(
                          'page.form.fields.furigana.errors.validation'
                        ),
                        value: /^[ァ-ヾ\u3000]*$/,
                      },
                      required: true,
                    }}
                  />
                </Grid>

                <Grid item className={classes.inputFieldContainer}>
                  <LabelBlock
                    htmlFor="destination-select"
                    name={translate('page.form.fields.destination.label')}
                  />
                  <FormControl fullWidth variant="outlined">
                    {isMobile ? (
                      <FormNativeSelectBlock
                        name="destination"
                        props={{
                          className: classes.select,
                          classes: {
                            icon: classes.selectIcon,
                            root: classes.selectRoot,
                          },
                        }}
                      ></FormNativeSelectBlock>
                    ) : (
                      <FormSelectBlock
                        name="destination"
                        props={{
                          className: classes.select,
                          classes: {
                            icon: classes.selectIcon,
                            root: classes.selectRoot,
                          },
                        }}
                      ></FormSelectBlock>
                    )}
                  </FormControl>
                </Grid>

                <Grid item className={classes.inputFieldContainer}>
                  <LabelBlock
                    htmlFor="phone"
                    name={translate('page.form.fields.phone.label')}
                  />
                  <FormTextField
                    error={errors.phone}
                    errorClassName={classes.error}
                    name="phone"
                    props={{
                      fullWidth: true,
                      InputLabelProps: { shrink: false },
                      InputProps: { className: classes.inputField },
                      placeholder: translate(
                        'page.form.fields.phone.placeholder'
                      ),
                    }}
                    rules={{
                      maxLength: {
                        message: translate(
                          'page.form.fields.phone.errors.length'
                        ),
                        value: 11,
                      },
                      minLength: {
                        message: translate(
                          'page.form.fields.phone.errors.length'
                        ),
                        value: 10,
                      },
                      pattern: {
                        message: translate(
                          'page.form.fields.phone.errors.validation'
                        ),
                        value: /^[0-9]*$/,
                      },
                      required: true,
                    }}
                  />
                </Grid>

                <Grid item className={classes.inputFieldContainer}>
                  <FormCheckbox
                    label={
                      <Trans
                        i18nKey="page.form.privacy"
                        components={[
                          <Link
                            className={classes.link}
                            key="https://www.his.co.jp/privacy/"
                            href="https://www.his.co.jp/privacy/"
                            rel="noreferrer noopener"
                            target="_blank"
                          />,
                        ]}
                      />
                    }
                    name="privacyAgreed"
                    props={{
                      classes: {
                        root: classes.checkbox,
                        checked: classes.checked,
                      },
                    }}
                    rules={{ required: true }}
                  />
                </Grid>

                <Grid item className={classes.inputFieldContainer}>
                  <Precautions />
                </Grid>

                <Grid item className={classes.inputFieldContainer}>
                  <Button
                    id="form-submit"
                    className={classes.button}
                    disabled={!formState.isValid}
                    loading={submitting}
                    type="submit"
                    variant="rounded"
                  >
                    {translate('page.form.submit')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>
      </LogoContainer>
      <NoMatchErrorModal dataErrorType={errorType} open={errorDialog} />
    </>
  );
};

export default FormPage;
