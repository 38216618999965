import { observable, action, runInAction } from 'mobx';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';
import {
  GetVideoTokenRequest,
  GetVideoTokenResponse,
} from '@zeals/shared-types/build/api/video';
import { RootStore } from './rootStore';
import api from '../services/api';

class VideoStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @action getVideoSessionToken = async (
    request: GetVideoTokenRequest
  ): Promise<ApiResponseWrapper<GetVideoTokenResponse>> => {
    if (this.fetching) return null;

    this.fetching = true;

    try {
      // add the person to the conversation
      const response = await api.getVideoSessionToken(request);
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      return response;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };
}

export default VideoStore;
