import { makeStyles } from '@material-ui/core/styles';
import { isIOS } from 'react-device-detect';

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    minHeight: isIOS ? '-webkit-fill-available' : '100vh',
    maxHeight: '100%',
    maxWidth: '100%',
    margin: 0,
  },
  title: {
    whiteSpace: 'break-spaces',
  },
  homeButtonContainer: {
    width: '100%',
    maxWidth: '280px',
    padding: theme.spacing(3),
  },
}));
