import { VendorConfig } from './types';

export type HISVendorConfig = VendorConfig & {
  ORIGIN: {
    HOKKAIDO: string;
    TOHOKU: string;
    KANTO: string;
    CHUBU_HOKURIKU: string;
    KANSAI: string;
    CHUGOKU_SHIKOKU: string;
    KYUSHU: string;
  };
  DESTINATION: {
    TBD: string;
    DOMESTIC: {
      HOKKAIDO: string;
      TOHOKU: string;
      KOSHINETSU: string;
      KANTO: string;
      TOUKAI: string;
      HOKURIKU: string;
      KANSAI: string;
      SANIN_SANYOU: string;
      SHIKOKU: string;
      KYUSHU: string;
      OKINAWA: string;
      OKINAWA_ISLANDS: string;
    };
    OVERSEAS: {
      ASIA: string;
      AMERICA_CANADA_LATIN_AMERICA: string;
      OCEANIA_SOUTH_PACIFIC_PALAU: string;
      HAWAI: string;
      EUROPE_NEAR_AND_MIDDLE_EAST_AFRICA: string;
    };
  };
  GOOGLE_TAG_MANAGER_ID: string;
};

const his: HISVendorConfig = {
  domain: 'his',
  ORIGIN: {
    HOKKAIDO: 'hokkaido',
    TOHOKU: 'tohoku',
    KANTO: 'kanto',
    CHUBU_HOKURIKU: 'chubu_hokuriku',
    KANSAI: 'kansai',
    CHUGOKU_SHIKOKU: 'chugoku_shikoku',
    KYUSHU: 'kyushu',
  },
  DESTINATION: {
    TBD: 'tbd',
    DOMESTIC: {
      HOKKAIDO: 'hokkaido',
      TOHOKU: 'tohoku',
      KOSHINETSU: 'koshinetsu',
      KANTO: 'kanto',
      TOUKAI: 'toukai',
      HOKURIKU: 'hokuriku',
      KANSAI: 'kansai',
      SANIN_SANYOU: 'sanin_sanyo',
      SHIKOKU: 'shikoku',
      KYUSHU: 'kyushu',
      OKINAWA: 'okinawa',
      OKINAWA_ISLANDS: 'okinawa_islands',
    },
    OVERSEAS: {
      ASIA: 'asia',
      AMERICA_CANADA_LATIN_AMERICA: 'america_canada_latin_america',
      OCEANIA_SOUTH_PACIFIC_PALAU: 'ocenia_south_pacific_palau',
      HAWAI: 'hawaii',
      EUROPE_NEAR_AND_MIDDLE_EAST_AFRICA: 'europe_near_and_middle_east_africa',
    },
  },
  GOOGLE_TAG_MANAGER_ID: 'GTM-TR72J3Z',
};

export default his;
