import { makeStyles } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';

export default makeStyles((theme) => {
  return {
    chatContainer: {
      background: `linear-gradient(0deg, ${rgba(
        theme.palette.common.black,
        0.8
      )} 0%, ${rgba(theme.palette.common.black, 0)} 100%)`,
      display: 'grid',
      gridTemplateRows: 'minmax(0, 1fr) minmax(0, auto) 10px',
      maxHeight: '35vh',
      overflow: 'hidden',
      position: 'relative',
    },
    chatDialog: {
      gridRow: 1,
      padding: '10px 0px 10px 0px',
    },
    inputContainer: {
      display: 'grid',
      gridTemplateColumns:
        'minmax(0, 16px) minmax(0, 1fr) 8px auto minmax(0, 16px)',
      gridRow: 2,
      maxWidth: '100%',
      '& >.sendButton': {
        gridColumn: 4,
        '& button': {
          width: '40px',
          height: '40px',
          background: theme.palette.common.white,
          color: theme.palette.primary.main,
        },
      },
    },
    input: {
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.white}`,
      borderRadius: '20px',
      maxHeight: '40px',
      boxSizing: 'border-box',
      gridColumn: 2,
      height: '100%',
      justifySelf: 'start',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: '100%',
    },
  };
});
