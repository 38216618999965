import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { useTranslation } from 'react-i18next';

import {
  Conversation,
  IMEInput,
  MessageInstance,
} from '@zeals/shared-components';
import { Conversation as IConversation } from '@twilio/conversations/lib/conversation';

import useStyles from './styles';

type ChatRoomProps = {
  conversation: IConversation;
  messages: MessageInstance[];
};

const Chat: React.FC<ChatRoomProps> = ({
  conversation,
  messages,
}: ChatRoomProps) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const [input, setInput] = useState('');
  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatDialog}>
        <Conversation
          style={{
            maxHeight: '100%',
          }}
          messages={messages}
          theme="dark"
        />
      </div>
      <div className={classes.inputContainer}>
        <IMEInput
          className={classes.input}
          disabled={!conversation}
          fullWidth
          disableUnderline
          placeholder={translate('component.chatRoom.inputPlaceholder')}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && input) {
              conversation.sendMessage(input);
              setInput('');
            }
          }}
          aria-label="free-input"
        />
        <div className="sendButton">
          <IconButton
            aria-label="send"
            disabled={!conversation}
            onClick={() => {
              if (input) {
                conversation.sendMessage(input);
                setInput('');
              }
            }}
          >
            <SendIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Chat;
