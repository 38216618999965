import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isAndroid } from 'react-device-detect';
import { Typography } from '@material-ui/core';

import {
  CallEndConfirmationModal,
  CallStatusBar,
  CompanyLogo,
  EarthLoadingIndicator,
  EndCallButton,
  MicToggle,
  Participant,
  SessionEndErrorModal,
  Spacer,
  useParticipantTracks,
  useTwilioVideo,
  useTwilioConversation,
  VideocamToggle,
  NetworkStatus,
  useNetworkQualityLevel,
  INetworkMessage,
  INetworkLevel,
  Alert,
  usePrevious,
} from '@zeals/shared-components';
import { matching } from '@zeals/shared-types';

import ChatVideoLoadingBlock from './loading/ChatVideoLoadingBlock';
// import NetworkNotification from './networkNotification/NetworkNotification';
import ChatRoom from '../../components/ChatComponent';

import { useStores } from '../../stores';
import useStyles from './styles';
import { Routes } from '../../navigations/Routes';

import config from '../../config';

type VideoPageParams = {
  roomId: string;
};

const VideoPage: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const {
    authStore,
    matchingStore,
    videoStore,
    conversationStore,
  } = useStores();

  const { roomId } = useParams<VideoPageParams>();
  const history = useHistory();
  const networkMessages: INetworkMessage = {
    noConnection: translate('page.networkNotification.noConnection'),
    lowConnection: translate('page.networkNotification.lowConnection'),
    highConnection: translate('page.networkNotification.highConnection'),
  };

  const [audioEnabled, setAudioEnabled] = useState(true);
  const [conversationToken, setConversationToken] = useState('');
  const [showCallEndConfirmation, setShowCallEndConfirmation] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [videoToken, setVideoToken] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  useEffect(() => {
    matchingStore
      .getRoom(roomId)
      .then(async () => {
        if (matchingStore.room.status === matching.RoomStatus.CLOSED) {
          setShowErrorModal(true);
          return;
        }

        const [
          videoTokenResponse,
          conversationTokenResponse,
        ] = await Promise.all([
          videoStore.getVideoSessionToken({
            videoSessionId: matchingStore.room.videoSessionId,
          }),
          conversationStore.getConversationToken({
            conversationId: matchingStore.room.conversationId,
          }),
        ]);

        setVideoToken(videoTokenResponse.data?.token);
        setConversationToken(conversationTokenResponse.data?.token);
      })
      .catch((err) => console.error(err));
  }, [authStore, matchingStore]);

  const { remoteParticipants, room, error: twilioError } = useTwilioVideo({
    token: videoToken,
    name: matchingStore.room && matchingStore.room.videoSessionId,
    localTracksOptions: {
      video: {
        enabled: videoEnabled,
        options: {
          // This is a temporary solution to fix the aspect ratio on android devices
          ...(isAndroid ? { width: 640, height: 360 } : {}),
        },
      },
      audio: {
        enabled: audioEnabled,
      },
      screen: {
        enabled: false,
      },
    },
    connectOptions: {
      name: matchingStore.room && matchingStore.room.videoSessionId,
      ...config.TWILIO_CONNECT_OPTIONS,
    },
  });

  const { conversation, messages } = useTwilioConversation(
    conversationToken,
    matchingStore.room && matchingStore.room.conversationId,
    authStore.user.id
  );

  const [activeLocalVideoTrack, activeLocalAudioTrack] = useParticipantTracks(
    room?.localParticipant
  );

  const networkLevel = useNetworkQualityLevel(
    room?.localParticipant,
    'end-user'
  ) as INetworkLevel;

  const preNetworkLevel = usePrevious<INetworkLevel | null>(
    networkLevel || null
  );

  useEffect(() => {
    const DISPLAY_SUCCESS_TIME = 3000;
    let timeout: ReturnType<typeof setTimeout>;
    if (
      preNetworkLevel <= 2 &&
      networkLevel > 2 &&
      preNetworkLevel !== networkLevel
    ) {
      setShowSuccessAlert(true);
      timeout = setTimeout(() => {
        setShowSuccessAlert(false);
      }, DISPLAY_SUCCESS_TIME);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [networkLevel]);

  useEffect(() => {
    // when there are no participant in the room, check the room status
    if (remoteParticipants.length === 0 && room) {
      matchingStore.getRoom(roomId).then(() => {
        if (matchingStore.room.status === matching.RoomStatus.CLOSED) {
          history.push(`/rooms/${roomId}/rating`);
        }
      });
    }
  }, [matchingStore, history, roomId, room, remoteParticipants]);

  if (!room) {
    if (showErrorModal || twilioError) {
      return (
        <SessionEndErrorModal
          onConfirm={() => history.push(Routes.SelectRegion)}
          open
        />
      );
    }

    return <ChatVideoLoadingBlock />;
  }
  return (
    <>
      <div className={classes.rootContainer}>
        <div className="topRow">
          <div className="statusBarContainer">
            <div className="statusBar">
              <CallStatusBar
                style={{
                  backgroundColor: 'inherit',
                  borderRadius: 0,
                  margin: 0,
                  padding: 0,
                  width: '220px',
                }}
                logo={<CompanyLogo company="his" reserveFill={false} />}
                remoteName="HISコンサルタント"
                remotePartcipant={
                  remoteParticipants.length > 0 && remoteParticipants[0]
                }
              />
              <NetworkStatus
                networkMessages={networkMessages}
                networkLevel={networkLevel}
              />
            </div>
          </div>
          <div className="localVideoContainer">
            {showSuccessAlert && (
              <div className="alertContainer">
                <Alert
                  showAlert
                  message={translate('page.networkNotification.connected')}
                  severity="success"
                  variant="filled"
                  onClick={() => {
                    setShowSuccessAlert(false);
                  }}
                />
              </div>
            )}
            {networkLevel === 0 && (
              <div className="alertContainer">
                <Alert
                  showAlert
                  message={translate('page.networkNotification.notConnected')}
                  severity="error"
                  variant="filled"
                  closeBtnMsg={translate('page.networkNotification.retry')}
                  onClick={() => {
                    window.location.reload();
                  }}
                />
              </div>
            )}
            <Participant
              className={classes.localVideoTag}
              local
              participant={room.localParticipant}
            />
          </div>
        </div>
        <div className="chat">
          <ChatRoom
            conversation={conversation}
            messages={messages.map((message) => {
              return {
                ...message,
                author:
                  message.direction === 'outgoing'
                    ? translate('common.you')
                    : matchingStore.admin.name,
              };
            })}
          />
        </div>
        <div className="controls">
          <EndCallButton
            label={translate('common.buttons.endCall')}
            onClick={() => {
              setShowCallEndConfirmation(true);
            }}
          />
          <VideocamToggle
            label={translate('common.buttons.camera')}
            loading={
              // either enabled but no active track or disblaed but with active track
              // will show loading to block further interaction until the track published/unpublished
              videoEnabled && !activeLocalVideoTrack
            }
            value={videoEnabled}
            onChange={(value) => setVideoEnabled(value)}
          />
          <MicToggle
            label={translate('common.buttons.microphone')}
            loading={
              // either enabled but no active track or disblaed but with active track
              // will show loading to block further interaction until the track published/unpublished
              audioEnabled && !activeLocalAudioTrack
            }
            value={audioEnabled}
            onChange={(value) => setAudioEnabled(value)}
            track={activeLocalAudioTrack}
          />
        </div>
      </div>
      <div className={classes.remoteVideoContainer}>
        {remoteParticipants.length > 0 ? (
          <>
            {networkLevel !== 0 ? (
              <Participant
                className={classes.remoteVideoTag}
                participant={remoteParticipants[0]}
              />
            ) : (
              <NetworkStatus
                className={classes.badNetworkIcon}
                hasStatusMsg={false}
                reserveFill
                networkLevel={0}
                scale={10}
              />
            )}
          </>
        ) : (
          <div className={classes.loadingIndicator}>
            <EarthLoadingIndicator />
            <Spacer size="m" />
            <Typography variant="h6" color="textSecondary">
              {translate('page.video.pleaseWait')}
            </Typography>
          </div>
        )}
      </div>
      <CallEndConfirmationModal
        onCancel={() => {
          setShowCallEndConfirmation(false);
        }}
        onConfirm={() => {
          history.push(`/rooms/${roomId}/rating`);
        }}
        open={showCallEndConfirmation}
      />
      <SessionEndErrorModal
        onConfirm={() => history.push(Routes.SelectRegion)}
        open={showErrorModal}
      />
    </>
  );
};

export default VideoPage;
