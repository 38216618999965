import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    labelContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(2),
      width: '100%',
      '& >.label': {
        color: theme.palette.common.white,
        fontSize: '1em',
        fontWeight: 900,
      },
      '& >.mandatoryLabel': {
        alignItems: 'center',
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        marginLeft: theme.spacing(1),
        maxHeight: '18px',
        maxWidth: '35px',
        width: '100%',
      },
    },
  };
});
