const ratings = [
  {
    name: 'attitude',
    kind: 'number',
    title: 'page.rating.question.attitude.title',
  },
  {
    name: 'planning',
    kind: 'number',
    title: 'page.rating.question.planning.title',
  },
  {
    name: 'knowledge',
    kind: 'number',
    title: 'page.rating.question.knowledge.title',
  },
  {
    name: 'usability',
    kind: 'number',
    title: 'page.rating.question.usability.title',
  },
  {
    name: 'quality',
    kind: 'number',
    title: 'page.rating.question.quality.title',
  },
  {
    name: 'recommendation',
    kind: 'number',
    title: 'page.rating.question.recommendation.title',
  },
  {
    name: 'comment',
    kind: 'text',
    title: 'page.rating.question.comment.title',
  },
];

export default ratings;
