import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import { useVendorConfig } from '../../../../config';
import { HISVendorConfig } from '../../../../config/vendors/his';

type FormSelectProps = {
  label?: string;
  name: string;
  props?: SelectProps;
};

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  name,
  props,
}: FormSelectProps) => {
  const { control } = useFormContext();
  const { t: translate } = useTranslation();
  const vendorConfig = useVendorConfig<HISVendorConfig>();

  return (
    <>
      <Controller
        as={
          <Select id="select-destination" {...props}>
            <MenuItem value={vendorConfig.DESTINATION.TBD}>
              {translate(`regions.${vendorConfig.DESTINATION.TBD}`)}
            </MenuItem>
            <ListSubheader>
              {translate('page.form.fields.destination.group.domestic')}
            </ListSubheader>
            {Object.values(vendorConfig.DESTINATION.DOMESTIC).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {translate(`regions.domestic.${val}`)}
                </MenuItem>
              );
            })}
            <ListSubheader>
              {translate('page.form.fields.destination.group.overseas')}
            </ListSubheader>
            {Object.values(vendorConfig.DESTINATION.OVERSEAS).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {translate(`regions.overseas.${val}`)}
                </MenuItem>
              );
            })}
          </Select>
        }
        control={control}
        defaultValue={vendorConfig.DESTINATION.TBD}
        label={label}
        name={name}
      />
    </>
  );
};

export default FormSelect;
